import React from "react";

function About() {
  return (
    <div>
      <h1>about page is opened</h1>
    </div>
  );
}

export default About;

import React from "react";

function Contacts() {
  return (
    <div>
      <h1>contact is opned</h1>
    </div>
  );
}

export default Contacts;
